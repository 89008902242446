<template>
  <loading-spinner v-if="loading"></loading-spinner>
  <div v-else>
    <div v-if="$store.getters['pages'].length > 0">
      <p class="text-center font-bold mt-16 text-2xl">اختر صفحة لتفعيل الخدمة عليها</p>
      <p class="text-lg text-center mb-16">يمكنك اختيار صفحات أُخرى فيما بعد</p>
      <div class="flex justify-center align-center content-center flex-wrap h-full p-10">
        <div
          v-for="page in $store.getters['pages']"
          :key="page.id"
          class="page-card flex flex-col justify-center align-center content-center m-6 rounded-lg shadow"
        >
          <div class="page-card-image">
            <div class="h-150 flex justify-center items-center content-center">
              <img :src="page.picture" :alt="page.name" />
            </div>
            <p class="text-center">{{ page.name }}</p>
          </div>
          <div class="page-card-controll flex justify-center items-center content-center">
            <button class="mujeeb-button green" @click="onPageSelected(page.id)">
              اختر هذه الصفحة
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="text-center">لم يتم العثور على اي صفحة تملك عليها صلاحيات المدير او المحرر</p>
    </div>
  </div>
</template>

<script>
import { StorageService } from "../services/storage.service";
export default {
  data() {
    return {
      loading: false
    };
  },
  methods: {
    onPageSelected(id) {
      this.loading = true;
      this.$api
        .customRequest({
          // url: `/user/${this.$store.getters["user/id"]}/pages/registered/${id}`,
          // url: `/services/users/${this.$store.getters["user/id"]}/registered-pages/${id}`,
          url: `/services/users/${this.$store.getters["user/id"]}/registered-pages/${id}`,
          method: "post",
          responseType: "json"
        })
        .then(({ data }) => {
          this.$store.dispatch("page/setPage", data);
          this.$router.push({ name: "dashboard" });
          return StorageService.savePage(data);
        })
        .then(() => {
          this.$store.dispatch("setAuthenticatingUser", false);
        })
        .catch(error => {
          // TODO show error message
          console.log(error);
          this.$router.push("/");
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.page-card {
  height: 300px;
  padding: 10px;
  .page-card-image {
    height: 200px;
    & > div {
      height: 150px;
      img {
        height: 65%;
      }
    }
    p {
      height: 50px;
    }
  }
}
</style>
